import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import useSetState from "react-use/lib/useSetState";
import axios from "axios";

import {Container, Input, Title, TextArea, Button, Error, Select, Body, Note} from "../../components/ContactForm";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import { urls } from "../../config";

export interface Props {}

const ContactBD: React.SFC<Props> = (props) => {
    const { contactFormBd: strings } = useStaticQuery(graphql`
        query ContactFormBD {
            contactFormBd {
                title
                namePlaceholder
                emailPlaceholder
                phoneNumberPlaceholder
                categoryPlaceholder
                messagePlaceholder
                validationError
                unknownError
                emailError
                phoneError
                submitButton
                gymCategory
                schoolCategory
                travelCategory
                brandCategory
                otherCategory
                finishedTitle
                finishedBody
                requiredNote
            }
        }
    `);
    const [state, setState] = useSetState({
        name: "",
        email: "",
        phoneNumber: "",
        category: "",
        message: "",
        error: ""
    });
    const [finished, setFinished] = React.useState(false);
    return (
        <PageWrapper {...props}>
            <Container>
                {finished ? (
                    <>
                        <Title>{strings.finishedTitle}</Title>
                        <Body>{strings.finishedBody}</Body>
                    </>
                ) : (
                    <>
                        <Title>
                            {strings.title}
                        </Title>
                        <Input
                            type="text"
                            value={state.name}
                            tabIndex={10}
                            placeholder={`${strings.namePlaceholder} *`}
                            onChange={(event) => setState({name: event.target.value, error: ""})}
                        />
                        <Input
                            type="email"
                            tabIndex={11}
                            value={state.email}
                            placeholder={`${strings.emailPlaceholder} *`}
                            onChange={(event) => setState({email: event.target.value, error: ""})}
                        />
                        <Input
                            type="tel"
                            tabIndex={12}
                            value={state.phoneNumber}
                            placeholder={strings.phoneNumberPlaceholder}
                            onChange={(event) => setState({phoneNumber: event.target.value, error: ""})}
                        />
                        <Select
                            tabIndex={13}
                            value={state.category}
                            onChange={(event) => setState({category: event.target.value, error: ""})}
                        >
                            <option value="">Category *</option>
                            <option value="gyms">Gyms</option>
                            <option value="universities-schools">Universities and Schools</option>
                            <option value="hotel-travel">Hotel/Travel</option>
                            <option value="fitness-brands">Fitness Brands</option>
                            <option value="corporate">Corporate</option>
                            <option value="trainer">Trainer</option>
                            <option value="other">Other</option>
                        </Select>
                        <TextArea
                            tabIndex={14}
                            value={state.message}
                            placeholder={`${strings.messagePlaceholder} *`}
                            onChange={(event) => setState({message: event.target.value, error: ""})}
                        />
                        <Note>{strings.requiredNote}</Note>
                        {state.error && <Error>{state.error}</Error>}
                        <Button 
                            type="submit"
                            tabIndex={15}
                            disabled={!!(!state.name || !state.email || !state.message || !state.category)}
                            onClick={async (event) => {
                                event.preventDefault();
                                if (!state.name || !state.email || !state.message || !state.category) {
                                    setState({error: strings.validationError});
                                    return;
                                }
                                const result = await axios({
                                    method: 'post',
                                    headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    },
                                    url: `${urls.contact}`,
                                    data: {
                                        name: state.name,
                                        email: state.email,
                                        phoneNumber: state.phoneNumber,
                                        category: state.category,
                                        message: state.message,
                                        contactType: "BUSINESS_DEVELOPMENT"
                                    },
                                });

                                if (result.data && result.data.error) {
                                    setState({ error: result.data.error });
                                } else if (result.data && result.data.result) {
                                    setFinished(true);
                                } else {
                                    setState({ error: strings.unknownError });
                                }
                            }}
                        >
                            {strings.submitButton}
                        </Button>
                    </>
                )}
            </Container>
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

export default ContactBD;
