import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { PageTitle as Title } from "@fitplan/lib/components/Header/PageTitle";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";


const lightColor = "#757575";
const darkColor = "#222";

export const inputStyles = css`
    font-family: Barlow;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;

    border: none;
    border-radius: 0;
    border-bottom: solid 1px ${lightColor};
    margin-bottom: 24px;
    padding: 8px;
    box-sizing: border-box;

    width: 100%;
    color: ${darkColor};

    ::placeholder {
        color: ${lightColor};
    }

    :hover,
    :focus {
        border-bottom: solid 1px ${darkColor};
        outline: none;
    }
`;

export const Input = styled.input`
    ${inputStyles}
`;

export const TextArea = styled.textarea`
    ${inputStyles}

    border: solid 1px ${lightColor};
    min-height: 200px;
    border-radius: 5px;
    resize: vertical;
    margin-bottom: 8px;

    :hover, :focus {
        border: solid 1px ${darkColor};
    }
`;

export const Select = styled.select`
    ${inputStyles}
    background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23757575%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
    color: ${lightColor};

    -moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;

    :hover, :focus {
        color: ${darkColor};
    }
`;

export const Container = styled.form`
    box-sizing: border-box;
    margin: auto;
    max-width: 412px;
    padding: 24px;
`;

export const Button = styled(BrandButton)`
    margin: auto;
    height: 56px;
    width: 170px;
`;

export const Error = styled.div`
    margin-bottom: 16px;
    color: #fc172f;
    text-align: center;
`;

export const Body = styled(Title)`
    font-size: 18px;
    font-weight: normal;

    @media (min-width: 600px) {
        font-size: 23px;
    }
`;

export const Note = styled.div`
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #f10364;
    margin-bottom: 16px;

    @media (min-width: 600px) {
        font-size: 16px;
    }
`;

export { Title };
